import { NgxLoggerLevel } from 'ngx-logger';

import { AppEnvironment } from './types';

export const environmentCommon: AppEnvironment = {
  production: false,
  apiBaseUrl: localStorage.getItem('apiBaseUrl') || window.location.origin + '/api',
  environment: 'local',
  logs: {
    logger: {
      level: NgxLoggerLevel.DEBUG,
    },
  },
  eid: {
    eidBaseUrl: '/fs/start',
    TOFEReturnUrl: 'https://topcl.infocert.it/fe/',
    infocertIdp: 'https://identitycl.infocert.it',
    spidLevel: 'https://www.spid.gov.it/SpidL1',
  },
};
