import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { NGXLogger } from 'ngx-logger';
import { from, of, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';

const LOG_PREFIX = '[Resolver][Language]';
const LOG_ENABLED = true;

const takeLangFromDevice = (logger: NGXLogger | null) =>
  from(Preferences.get({ key: 'tofe-language' })).pipe(
    switchMap(({ value }) =>
      value
        ? of(value).pipe(
            tap((y) => {
              if (LOG_ENABLED && logger) logger.debug(`${LOG_PREFIX} get from storage`, y);
            }),
          )
        : from(Device.getLanguageCode()).pipe(
            map(({ value }) => (value || '').substring(0, 2)),
            tap((y) => {
              if (LOG_ENABLED && logger) logger.debug(`${LOG_PREFIX}get from device`, y);
            }),
          ),
    ),
  );

export const languageResolver: ResolveFn<string> = (route) => {
  const logger = inject(NGXLogger, { optional: true });
  const lang = route.queryParamMap.get('lang') as string;
  if (LOG_ENABLED && logger) logger.debug(LOG_PREFIX + ' hit', lang);
  if (lang) {
    return of(lang);
  }
  return takeLangFromDevice(logger);
};
