import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { marker as _ } from '@jsverse/transloco-keys-manager/marker';
import { errorState, HttpRequestState, httpRequestStates, loadedState } from 'ngx-http-request-state';
import { NGXLogger } from 'ngx-logger';
import { defer, Observable, of, switchMap } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { ProcessInfo, ProcessService, StartErrorResponse, StartResponse } from 'top-api-sdk-angular';

import { SessionInfo } from '../types';
import { isMobile } from '../utils';

const LOG_PREFIX = '[Resolver][Process]';
const LOG_ENABLED = true;

const DEFAULT_ERROR_MESSAGE = _('TOFE.PROCESS_RESOLVER.GENERIC_ERROR');

export type SessionInfoRequestState = HttpRequestState<SessionInfo>;

export class StartError extends Error {}

/**
 * t(TOFE.PROCESS_RESOLVER.GENERIC_ERROR.TITLE)
 * t(TOFE.PROCESS_RESOLVER.GENERIC_ERROR.DESCRIPTION)
 * t(TOFE.PROCESS_RESOLVER.AUTH_FAILED.TITLE)
 * t(TOFE.PROCESS_RESOLVER.AUTH_FAILED.DESCRIPTION)
 * t(TOFE.PROCESS_RESOLVER.USED_TOKEN.TITLE)
 * t(TOFE.PROCESS_RESOLVER.USED_TOKEN.DESCRIPTION)
 * t(TOFE.PROCESS_RESOLVER.EXPIRED_TOKEN.TITLE)
 * t(TOFE.PROCESS_RESOLVER.EXPIRED_TOKEN.DESCRIPTION)
 */
const getStartErrorMessage = (payload: StartErrorResponse) => {
  switch (payload.errorKey) {
    case 'SK099':
      return 'TOFE.PROCESS_RESOLVER.AUTH_FAILED';
    case 'COP-0001':
      return 'TOFE.PROCESS_RESOLVER.USED_TOKEN';
    case 'INVALID_TOKEN':
      if (payload.errorMessage.includes('expired')) {
        return 'TOFE.PROCESS_RESOLVER.EXPIRED_TOKEN';
      } else {
        return 'TOFE.PROCESS_RESOLVER.INVALID_TOKEN';
      }
    default:
      return DEFAULT_ERROR_MESSAGE;
  }
};

const interceptStartErrors = (x: HttpRequestState<StartResponse>) => {
  if (x.error instanceof HttpErrorResponse) {
    const originalError = x.error;
    const payload = originalError.error as StartErrorResponse;
    const error = new StartError(getStartErrorMessage(payload));
    return { ...x, error, originalError };
  }
  return x;
};

export const processResolver: ResolveFn<Observable<Observable<SessionInfoRequestState>>> = (route) => {
  const api = inject(ProcessService);
  const logger = inject(NGXLogger, { optional: true });
  const id = route.queryParamMap.get('id') as string;
  if (LOG_ENABLED && logger) logger.debug(LOG_PREFIX + ' hit', id);
  return defer(() => of(getProcessObservable(api, logger, id)));
};

const mergeProcessInfoInSession =
  (id: string, startResult: HttpRequestState<StartResponse>) => (processResult: HttpRequestState<ProcessInfo>) => {
    if (processResult.value) {
      return loadedState<SessionInfo>({
        id,
        startData: startResult.value,
        processData: processResult.value,
      });
    } else {
      return processResult as HttpRequestState<SessionInfo>;
    }
  };

const interceptProcessErrors = (res: HttpRequestState<SessionInfo>): HttpRequestState<SessionInfo> => {
  const isMobileOnlyError = res.value?.processData?.tofeConfiguration?.mobileOnly && !isMobile();
  if (isMobileOnlyError) {
    return { isLoading: false, error: new StartError('mobile_only') };
  }
  return res;
};

const getProcessObservable = (
  api: ProcessService,
  logger: null | NGXLogger,
  id?: string,
): Observable<SessionInfoRequestState> => {
  if (!id) {
    return of(errorState<SessionInfo>(new StartError(DEFAULT_ERROR_MESSAGE)));
  }
  return api.startProcess(id, { id }).pipe(
    httpRequestStates(),
    map(interceptStartErrors),
    switchMap((startResult) => {
      if (startResult.isLoading || startResult.error) {
        return of(startResult as HttpRequestState<SessionInfo>);
      }
      const sessionKey = startResult?.value?.sessionKey;
      if (!sessionKey) {
        // it is still possible that start returns a valid response but without a sessionKey
        return of({
          ...errorState<SessionInfo>(new StartError(DEFAULT_ERROR_MESSAGE)),
          originalValue: startResult.value,
        });
      }
      api.configuration.credentials['CopApiKey'] = 'COP ' + sessionKey;
      return api.getProcess(id).pipe(httpRequestStates(), map(mergeProcessInfoInSession(id, startResult)));
    }),
    tap((res) => {
      if (LOG_ENABLED && logger) logger.debug(LOG_PREFIX, res);
    }),
    map(interceptProcessErrors),
    shareReplay(1),
  );
};
