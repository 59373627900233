import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StartResponse } from 'top-api-sdk-angular';

import { environment } from '../../../environments/environment';
import { ProcessInResponseService } from '../services/process-in-response.service';

/**
 * some responses redefine the session data, so we need to update the session data with the new one
 * this interceptor is used to update the session data based on the response
 * when a task is completed we need to update CopApiKey with new a new sessionKey
 */
@Injectable()
export class ProcessInResponseInterceptor implements HttpInterceptor {
  constructor(private processInResponseService: ProcessInResponseService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Check if the request URL contains the specific base path, otherwise ignore.
    if (!request.url.includes(environment.apiBaseUrl)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.handle(event);
        }
      }),
    );
  }

  /**
   * here if the response contains a process we update the session data
   * but not directly, we use a service with a BehaviorSubject and topSessionService
   * will subscribe to it and update the session data
   *
   * do not try to update the session data directly here, it will not work due to
   * the way circular dependencies are handled in angular
   */
  private handle(event: HttpResponse<unknown>) {
    if (!event.body) {
      return;
    }

    const body = event.body as { process?: StartResponse };
    const newStartResponse = (body as { process?: StartResponse })?.process;
    if (!newStartResponse?.sessionKey) {
      return;
    }

    this.processInResponseService.setStartResponse(newStartResponse);
  }
}

export const ProcessInResponseInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ProcessInResponseInterceptor,
  multi: true,
};
