import { Device } from '@capacitor/device';

import { environment } from '../../environments/environment';

export const isMobile = async () => {
  const { operatingSystem } = await Device.getInfo();
  return operatingSystem === 'ios' || operatingSystem === 'android';
};

export const isProductionEnv = environment.environment === 'prod';

export const redirect = (href: string) => {
  window.location.href = href;
};

export const reload = () => {
  window.location.reload();
};

export const download = (url: string, name: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.click();
};
