import { NgModule, ModuleWithProviders } from '@angular/core';
import { LanguageConfigurationService } from './language-configuration.service';
import { provideTranslations } from './provide-translations';
import { TofeLanguageModuleConfig } from './types';
import { LanguageService } from './language.service';

@NgModule({})
export class TofeLanguageModule {
  static forRoot(config: TofeLanguageModuleConfig): ModuleWithProviders<TofeLanguageModule> {
    LanguageConfigurationService.moduleConfig = config;
    return {
      ngModule: TofeLanguageModule,
      providers: [LanguageConfigurationService, LanguageService, ...provideTranslations()],
    };
  }
}
